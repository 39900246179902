import { useSelector } from "react-redux";

function useAgs() {
  const roles = useSelector((state) => state.login.data?.user?.roles);

  const agsKeys = roles && Object.keys(roles);
  return agsKeys;
}

export default useAgs;
