import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "../reducers/rootReducer";

const middlewares = [thunk];
const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") return appReducer(undefined, action); // Reset all state
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
