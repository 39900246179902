import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const riskReportsPending = () => {
  return {
    type: actionTypes.RISK_REPORTS_PENDING,
  };
};
const riskReportsSuccess = (data) => {
  return {
    type: actionTypes.RISK_REPORTS_SUCCESS,
    data: data,
  };
};

const riskReportsFail = (error) => {
  return {
    type: actionTypes.RISK_REPORTS_FAIL,
    error: error,
  };
};

export const riskReportsAction = (ags, page, limit, type) => {
  let urlEndPoint = `riskreports/getAll?ags=${ags}`;

  return async (dispatch) => {
    dispatch(riskReportsPending());
    console.log(type);
    Axios.post(
      urlEndPoint,
      {
        page: page,
        limit: limit,
        type: "",
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        dispatch(riskReportsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(riskReportsFail(error));
      });
  };
};
