import * as actionType from "../actionTypes";
import Axios from "../../../services/NetworkService";
import { sensorTypes } from "../../../constants/sensorTypes";
import store from "../../store/store";

export const mixChartPending = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_PENDING,
    payload: "loading...",
  };
};

export const mixChartSuccess = (data) => {
  return {
    type: actionType.ANALYTICS_MIXCHART_SUCCESS,
    payload: data,
  };
};

export const mixChartFail = (error) => {
  return {
    type: actionType.ANALYTICS_MIXCHART_FAIL,
    error: error,
  };
};

export const mixChartLoaded = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_LOADED,
  };
};

export const mixChartReset = () => {
  return {
    type: actionType.ANALYTICS_MIXCHART_RESET,
  };
};

const fetchSensorsData = async (start, end, sensors, dispatch) => {
  const smartRiverData = [];
  const smartRiverIds = [];
  const hdcData = [];
  const hdcIds = [];
  const gflData = [];
  const gflIds = [];

  for (let i = 0; i < sensors.length; i++) {
    if (store.getState().mixChart.isTerminated) {
      dispatch(mixChartReset());
      break;
    }
    const sensorName = sensors[i].label;
    const sensorType = sensors[i].sensorType;
    const sensorId = sensors[i].value;
    const soleCorrectionValue =
      sensors[i].soleCorrectionValue ?? sensors[i].soleCorrectionValue;

    if (sensorType === sensorTypes.SMARTRIVER) {
      smartRiverData.push({
        soleCorrectionValue,
        sensorName,
        sensorId,
        values: [],
      });
      smartRiverIds.push(sensorId);
    } else if (sensorType === sensorTypes.HDC) {
      hdcData.push({
        sensorName,
        sensorId,
        values: [],
      });
      hdcIds.push(sensorId);
    } else if (sensorType === sensorTypes.GFL) {
      gflData.push({
        sensorName,
        sensorId,
        values: [],
      });
      gflIds.push(sensorId);
    }
  }

  // get duration from start and end date . dates are in ISO format
  const duration = (new Date(end) - new Date(start)) / 1000 / 60;

  if (smartRiverIds.length > 0) {
    const result = await Axios.post("/smartriverdata", {
      start,
      end,
      deviceIds: smartRiverIds,
      // resolution: duration === 1440 ? 5 : 60,
      resolution: 60,
      filter: true,
    });
    smartRiverData.forEach(
      (d) => (d.values = result.data.data[d.sensorId] || [])
    );
    await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
  }

  if (hdcIds.length > 0) {
    const result = await Axios.post("/hdcdata", {
      start,
      end,
      deviceIds: hdcIds,
      resolution: 60,
    });
    hdcData.forEach((d) => (d.values = result.data.data[d.sensorId] || []));
    await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
  }

  if (gflIds.length > 0) {
    const result = await Axios.post("/gfldata", {
      start,
      end,
      resolution: 60,
      deviceIds: gflIds,
    });
    gflData.forEach((d) => (d.values = result.data.data[d.sensorId] || []));
    await dispatch(mixChartSuccess({ smartRiverData, hdcData, gflData }));
  }

  dispatch(mixChartLoaded());
};

export const mixChartAction = (start, end, sensors) => {
  start.setUTCHours(0, 0, 0, 0);
  end.setUTCHours(0, 0, 0, 0);
  return async function (dispatch) {
    try {
      dispatch(mixChartPending());
      fetchSensorsData(start, end, sensors, dispatch);
    } catch (error) {
      return dispatch(mixChartFail(error));
    }
  };
};
