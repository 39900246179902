/*
 * Title: smartriver reducer
 * Description: smartriver sensor reducer
*/

import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const externalfsInit = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const externalfsSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const externalfsFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.EXTERNALFS_INIT: return externalfsInit(state);
        case actionTypes.EXTERNALFS_SUCCESS: return externalfsSuccess(state, data);
        case actionTypes.EXTERNALFS_FAIL: return externalfsFail(state, error);
        default: return state;
    }

};

export default reducer;