import { useSelector } from "react-redux";

function useObserver() {
  const roles = useSelector((state) => state.login.data?.user?.roles);

  //multiple ags => observer
  const isObserver = roles ? Object.keys(roles).length > 1 : false;
  return isObserver;
}

export default useObserver;
