import { combineReducers } from "redux";

// auth
import loginReducer from "./auth/loginReducer";
import registerReducer from "./auth/registerReducer";
import forgetPassReducer from "./auth/forgetPassReducer";
import deleteAccount from "./auth/deleteAccountReducer";

// realtime map
import observerReducer from "./realtimeMap/observerReducer";
import chartReducer from "../reducers/realtimeMap/chartReducer";
import catchmentReducer from "./realtimeMap/catchmentReducer";
import dangerPage from "./realtimeMap/dangerPage";
import podReducer from "./realtimeMap/podReducer";
import riverscapeReducer from "./realtimeMap/riverscapeReducer";

// common reducers
import hdcReducer from "./common/hdcReducer";
import gflReducer from "./common/gflReducer";
import smartriverReducer from "./common/smartriverReducer";
import customerReducer from "./common/customerReducer";
import smartsewerReducer from "./common/smartsewerReducer";
import externalfsReducer from "./common/externalfsReducer";

// users reducer
import usersReducer from "./userManagerReducers/usersReducer";
import userReducer from "./userManagerReducers/userReducer";
import updateUserReducer from "./userManagerReducers/updateUserReducer";

// addSpecialUser reducer
import addSpecialUserReducer from "../reducers/userManagerReducers/addSpecialUserReducer";

// subscribers reducer
import subscribersReducer from "./userManagerReducers/subscribersReducer";
import subscriberReducer from "./userManagerReducers/subscriberReducer";

// update subscriber reducer
import ownRescueSubscriberResucer from "./userManagerReducers/ownRescueSubscriberReducer";

// add subscriber reducer
import addSubscriberReducer from "./userManagerReducers/addSubscriberReducer";

import editCustomSubscriberReducer from "./userManagerReducers/editCustomSubscriberReducer";
import deleteCustomSubReducer from "./userManagerReducers/delCusSubReducer";

// all custom subscribers reducer
import allCustomSubscribersReducer from "./userManagerReducers/allCustomSubscribersReducer";

// analytics mix-chart
import mixChart from "./analytics/mixChartReducer";

// all communities
import allCommunitiesReducer from "./allCommunities/allCommunitiesReducer";

// sensor manager
import smartRiverSensor from "./sensorManager/smartRiverSensor";
import gflSensor from "./sensorManager/gflSensor";
import hdcSensor from "./sensorManager/hdcSensor";
import smartSewerSensor from "./sensorManager/smartSewerSensor";

// planing tool
import webviewComments from "./webviewComments/webviewComments";
import localOLFeature from "./webviewComments/localOLFeature";
import webviewer from "./webviewer/webviewer";

// risk
import riskReportsReducer from "./risk/riskReportsReducer";
import riskReporsProcessedReducer from "./risk/riskReporsProcessedReducer";
import riskSubmitReducer from "./risk/riskSubmitReducer";
import riskHandleReducer from "./risk/riskHandleReducer";

// theming
import theme from "./theming/themingReducer";

//notifications
import sensorNotification from "./notification/sensor";
import catchmentNotification from "./notification/catchmentReducer";
import dwdReducer from "./notification/dwdReducer";
import sticyNotification from "./notification/stickyNotification";
import phoneCheckReducer from "./notification/phoneCheckReducer";

//harm reports
import harmReportsReducer from "./melder/harmReports";

//harm notes
import harmNotesReducer from "./melder/harmNotes";

// municipality
import municipalityReducer from "./municipality/municipalityReducer";

// change email
import changeEmailReducer from "./auth/changeEmailReducer";

// app pop up
import appPopUpReducer from "./appPopUp/appPopUpReducer";

// start registration
import startRegister from "./userManagerReducers/startRegisterReducer";

// verify register token
import verifyRegTokenReducer from "./userManagerReducers/verifyRegTokenReducer";

// complete register process
import completeRegisterReducer from "./userManagerReducers/completeRegisterReducer";

// delete user
import deleteUserReducer from "./userManagerReducers/deleteUserReducer";

// delete risk report
import deleteRiskReportReducer from "./risk/riskDeleteReducer";

const appReducer = combineReducers({
  login: loginReducer,
  chart: chartReducer,
  register: registerReducer,
  hdc: hdcReducer,
  gfl: gflReducer,
  smartriver: smartriverReducer,
  smartsewer: smartsewerReducer,
  externalfs: externalfsReducer,
  forgetPass: forgetPassReducer,
  observer: observerReducer,
  dangerPage: dangerPage,
  catchment: catchmentReducer,
  riverscape: riverscapeReducer,
  customer: customerReducer,
  subscribers: subscribersReducer,
  users: usersReducer,
  user: userReducer,
  updateUser: updateUserReducer,
  specialUser: addSpecialUserReducer,
  addSubscriber: addSubscriberReducer,
  allCustomSubscribers: allCustomSubscribersReducer,
  editCustomSubscriber: editCustomSubscriberReducer,
  deleteCustomSub: deleteCustomSubReducer,
  subscriber: subscriberReducer,
  ownRescueSubscriber: ownRescueSubscriberResucer,
  mixChart: mixChart,
  deleteAccount: deleteAccount,
  webviewComments: webviewComments,
  webviewer: webviewer,
  localOLFeature: localOLFeature,
  riskReports: riskReportsReducer,
  riskSubmit: riskSubmitReducer,
  riskHandleInquiry: riskHandleReducer,
  riskReportsProcessed: riskReporsProcessedReducer,
  theme: theme,
  harmReports: harmReportsReducer,
  harmNotes: harmNotesReducer,
  smartRiverSensor: smartRiverSensor,
  gflSensor: gflSensor,
  hdcSensor: hdcSensor,
  smartSewerSensor: smartSewerSensor,
  municipality: municipalityReducer,
  allCommunities: allCommunitiesReducer,
  sensorNotification: sensorNotification,
  catchmentNotification: catchmentNotification,
  dwd: dwdReducer,
  stickyNotification: sticyNotification,
  phoneCheck: phoneCheckReducer,
  pod: podReducer,
  changeEmail: changeEmailReducer,
  appPopUp: appPopUpReducer,
  startRegister: startRegister,
  verifyRegToken: verifyRegTokenReducer,
  completeRegister: completeRegisterReducer,
  deleteUser: deleteUserReducer,
  deleteRiskReport: deleteRiskReportReducer,
});

export default appReducer;
