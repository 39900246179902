/*--------⚠️ disclaimer ⚠️-------
If you use this file, Be sure to mention
your file or function name below..    
*/

/*--------file in Used 📁 ------
Anaytics page: /container/analytics/analytics.js
Analytics Redux: /redux/actions/analytics/mixChart.js
*/

export const sensorTypes = {
  SMARTRIVER: "smartriverdata",
  HDC: "hdcdata",
  GFL: "gfldata",
  SMARTSEWER: "smartsewerdata",
  EXTERNALFS: 'externalfs',
};
