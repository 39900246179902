import * as actionType from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  sensorName: null,
  sensorType: null,
  optionalData: null,
  error: null,
};

export default function chartReducer(state = initialState, action) {
  const { type, payload, sensorName, sensorType, optionalData, error } = action;
  switch (type) {
    case actionType.CHART_PENDING:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        sensorName: null,
        sensorType: null,
        optionalData: null,
      };

    case actionType.CHART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        sensorName,
        sensorType,
        optionalData,
        error: null,
      };
    case actionType.CHART_FAIL:
      return {
        ...state,
        isLoading: false,
        data: null,
        sensorName: null,
        sensorType: null,
        optionalData: null,
        error: error,
      };

    default:
      return state;
  }
}
